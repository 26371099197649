@font-face {
  font-family: "Helvetica Neue";
  src: url(./HelveticaNeue-Bold.otf) format("otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(./HelveticaNeue-Medium.otf) format("otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(./HelveticaNeue.otf) format("otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(./HelveticaNeue-Light.otf) format("otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
