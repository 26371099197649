:root {
  --primary: #122344;
  --primary-contrast: #ccc;
  --nav-primary: #122344;
  --nav-size: 16px;
  --nav-bg: white;
  --nav-bg-contrast: #333;
  --text-font: "content_font", "Helvetica Neue", sans-serif;
  --heading-font: "heading_font", "Helvetica Neue", sans-serif;
}

html,
body {
  font-family: var(--text-font) !important;
  color: #666666 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--heading-font) !important;
}

// for components like feedback, subscribe, etc.
.small-container {
  height: 290px !important;
  min-height: 0 !important;
}

.background-gray {
  background-color: #808080;
}

.list-group-item {
  padding: 0.5rem;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.font-weight-500 {
  font-weight: 500;
}

// for easy fixes
.f-8px {
  font-size: 8px;
}

.f-10px {
  font-size: 10px;
}

.f-12px {
  font-size: 12px;
}

.f-14px {
  font-size: 14px;
}

.f-16px {
  font-size: 16px;
}

.f-18px {
  font-size: 18px;
}

.f-26px {
  font-size: 26px;
}

.width-fit-content {
  width: fit-content;
}

.height-fit-content {
  height: fit-content;
}

.min-width-fit-content {
  min-width: fit-content;
}

.primary {
  color: var(--primary);
}

.h-46px {
  height: 46px !important;
}

.w-25p {
  width: 25%;
}

.w-75p {
  width: 75%;
}

.w-33p {
  width: 33.333333%;
  flex: 1 1 33.333333%;
}

.w-66p {
  width: 66.666666%;
  flex: 1 1 66.666666%;
}

.flex-1-1-auto {
  flex: 1 1 auto;
}

.min-width-0 {
  min-width: 0;
}

.text-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

// simple css-only text-tooltip
.ctooltip {
  position: relative;
}

.ctooltip::after {
  background-color: #ececec;
  color: black;
  border-radius: 10px;
  padding: 4px 10px;
  position: absolute;
  text-align: center;
  width: fit-content;
  z-index: 999;
  font-size: 12px;
  display: none;
}

.ctooltip::before {
  background-color: #ececec;
  content: " ";
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
}

.ctooltip:hover::after {
  display: block;
}

.ctooltip:hover::before {
  display: block;
}

.ctooltip.ctooltip-top::after {
  content: attr(data-tooltip);
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.ctooltip.ctooltip-top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.ctooltip.ctooltip-bottom::after {
  content: attr(data-tooltip);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.ctooltip.ctooltip-bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.ctooltip.ctooltip-right::after {
  content: attr(data-tooltip);
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.ctooltip.ctooltip-right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.ctooltip.ctooltip-left::after {
  content: attr(data-tooltip);
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}

.ctooltip.ctooltip-left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

.cursor-pointer {
  cursor: pointer;
}

// remove close button from marker infowindow
#delivery-order-map button.gm-ui-hover-effect {
  display: none !important;
}

.pb-14px {
  padding-bottom: 14px;
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}

// order id title of order details used by all order type component
.orderIdTitle {
  font-size: 1.2rem;
  font-weight: normal;
  color: #000;
}

.pointer-events-none {
  pointer-events: none;
}

// DL-6649
// reduce the width of container to 920px (+ 30px padding given by bootstrap)
// reduce padding of columns to 7px
.max-950px {
  max-width: 950px;
}
.padding-right-7px {
  padding-right: 7px;
}
.padding-left-7px {
  padding-left: 7px;
}

.center-absolutely {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overflow-y-auto {
  overflow-y: auto;
}

.border-bottom-1px {
  border-bottom: 1px solid #ccc;
}

.border-right-1px {
  border-right: 1px solid #ccc;
}

.primary-btn {
  display: block;
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--primary-contrast);
  width: 75%;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 2px;
  height: 46px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  &:hover, &:active, &:focus {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: var(--primary-contrast) !important;
    box-shadow: 0 0 0 0.2rem #ccc;
  }
}

.modal-backdrop {
  // We are using ngb-modal-backdrop which has a z-index style applied right on the HTML element.
  // As ngb-modal-backdrop is a third party component, we cannot change the z-index directly,
  // override this style applied on the element we have to use !important.
  z-index: 1050 !important;
}

ds-images-modal {
  .imagesContainer {
    position: relative;

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 12h13M12 5l7 7-7 7'/%3E%3C/svg%3E");
      position: absolute;
      bottom: -35px;
      left: 40px;
      width: 20px;
      height: 20px;
    }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='%23080000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M19 12H6M12 5l-7 7 7 7'/%3E%3C/svg%3E");
      position: absolute;
      bottom: -35px;
      right: 40px;
      width: 20px;
      height: 20px;
    }

    .visually-hidden {
      display: none;
    }

    .slide {
      outline: none;
    }
    
    .modal-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 10px;
      width: 440px;
      height: 40px;
  
      .btn-close {
        font-weight: 400;
        font-size: 0.75rem;
        opacity: 1;
      }
  
      .btn-close:hover {
        opacity: 0.7;
      }
    }
  
    .carousel-indicators {
      button {
        padding: 2px 10px;
        border-radius: 5px;
        border: none;
        margin: 0 2px;
      }
    }
  
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      opacity: 1;
    }
  
    .carousel-control-prev-icon:hover,
    .carousel-control-next-icon:hover {
      opacity: 1;
      cursor: pointer;
    }
  
    .carousel-control-prev.disabled {
      opacity: 0.15;
      pointer-events: none;
    }
  
    .carousel-control-next.disabled {
      opacity: 0.15;
      pointer-events: none;
    }
  
    .picsum-img-wrapper img {
      border-radius: 5px;
    }
  }
}